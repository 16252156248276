<template>
  <div class="modal-card">
    <div class="modal-content">
      <div class="box">
        <div v-if="step === 1">
          <div class="has-text-right">
            <a class="has-text-danger" @click="$modal.close">Close</a>
          </div>
          <section class="content">
            <div class="has-text-centered">
              <img
                :src="require('core/img/icons/upsell-companion.svg')"
                width="250"
              />
            </div>
            <p class="title is-4 has-text-primary">
              Find out how to price this vehicle in today’s market with
              Percayso's retail-back valuations
            </p>
            <p>
              Get the real-time market value of this vehicle, along with loads
              of other great insight like depreciation whilst in-stock, value
              breakdown by dealer type and view of all of the other vehicles on
              sale right now of this type
            </p>
          </section>
          <div class="buttons is-right">
            <button class="button is-rounded is-soft" @click="step = 2">
              Get started
            </button>
          </div>
        </div>
        <section v-else>
          <BusinessEnquiry enquiry-type="companion" />
        </section>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UpsellCompanion',
  data: () => ({ step: 1 }),
  components: {
    BusinessEnquiry: () =>
      import('modules/contact/components/cazana/BusinessEnquiry')
  }
}
</script>
